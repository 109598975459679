*, *::before, *::after { box-sizing: border-box; }

.App {
  text-align: center;
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex-direction: column;
}

.App-logo {
  width: 65vmin;
  pointer-events: none;
}

.App-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: black;
  z-index: 100;
  background-color: rgba(255,255,255,0.90);
  backdrop-filter: blur(8px);
  border-bottom: 0.5px solid #ccc;
  padding-top: 1em;
}
footer.App-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(255,255,255,0.90);
  backdrop-filter: blur(8px);
  border-top: 0.5px solid #ccc;
  padding-bottom: 1em;
}

.App-header p {
  align-self: flex-end;
  padding-right: 1em;
}


.App main {
  flex-grow: 1;
  overflow: scroll;
  padding-top: 25vmin;
  padding-bottom: 25vmin;
}


footer .barcode {
  height: 16vmin;
}

footer .col {
  display: flex;
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}
